<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Downloadkategorien">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="handleRegionChange"
        ></region-chooser>
        <el-button
          type="primary"
          icon="fal fa-plus"
          @click="newDownloadCategory"
        >
          Neue Kategorie
        </el-button>
      </template>

      <el-input
        v-model="computedSearchQuery"
        placeholder="Durchsuchen…"
        clearable
        style="max-width: 500px;"
      >
        <i slot="prefix" class="el-icon-search el-input__icon"></i>
      </el-input>

      <data-loader
        ref="dataloader"
        :endpoint="
          `/admin/download_categories?page=${currentPage}&query=${remoteSearchQuery}&region_id=${$store.state.auth.chosenRegion}`
        "
      >
        <template #loaded="{data}">
          <el-table
            style="width: 100%;"
            empty-text="Keine Ergebnisse"
            stripe
            :data="data.download_categories"
          >
            <el-table-column prop="name" label="Name"></el-table-column>

            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <delete-button
                  v-slot="slotProps"
                  :on-delete="deleteDownloadCategory"
                  subject="Downloadkategorie"
                >
                  <el-button
                    size="mini"
                    icon="fal fa-trash-alt"
                    type="danger"
                    @click="slotProps.handleDelete(scope.row)"
                  >
                    Löschen
                  </el-button>
                </delete-button>
              </template>
            </el-table-column>
          </el-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>
  </div>
</template>

<script>
import * as log from "loglevel"
import { debounce } from "lodash-es"
import DownloadCategoriesRepository from "@/repositories/download_categories_repository.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"
import DeleteButton from "@/components/DeleteButton.js"

export default {
  metaInfo: {
    title: "Downloadkategorien"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DataLoader,
    DeleteButton,
    ThePagination
  },
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }

      if (to.query.query) {
        log.info(`setting query to ${to.query.query}`)
        this.localSearchQuery = to.query.query
        this.remoteSearchQuery = to.query.query
      } else {
        log.info(`setting query to ""`)
        this.localSearchQuery = ""
        this.remoteSearchQuery = ""
      }
    }
    next()
  },
  data() {
    return {
      currentPage: parseInt(this.$route.query.page) || 1,
      localSearchQuery: this.$route.query.query || "",
      remoteSearchQuery: this.$route.query.query || ""
    }
  },
  computed: {
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(value) {
        this.localSearchQuery = value
        this.debouncedSearch(value)
      }
    }
  },
  methods: {
    handleRegionChange() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: 1,
          query: "",
          region_id: this.$store.state.auth.chosenRegion
        }
      })
    },
    debouncedSearch: debounce(
      async function(query) {
        log.info(`Searching for "${query}"…`)
        this.remoteSearchQuery = query
        this.currentPage = 1
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: 1,
            query: query
          }
        })
      },
      300,
      { maxWait: 3000 }
    ),
    handleEdit(downloadCategory) {
      this.$router.push({
        name: "EditDownloadCategoryPage",
        params: { id: downloadCategory.id }
      })
    },
    async deleteDownloadCategory(downloadCategory) {
      await DownloadCategoriesRepository.destroy(downloadCategory.id)
      this.$refs.dataloader.loadData()
    },
    newDownloadCategory() {
      this.$router.push({ name: "NewDownloadCategoryPage" })
    }
  }
}
</script>
